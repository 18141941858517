<script lang="ts">
  import { appState, WelcomePageState, MODE_WELCOME_PAGE } from "./app-state";
  function goHome() {
    let newAppState: WelcomePageState = { type: MODE_WELCOME_PAGE };
    appState.set(newAppState);
  }

  export let compactMode = false;
</script>

<main>
  <div id="container">
    <div id="top" class:compact={compactMode}>
      <h1 id="title" on:click|preventDefault={goHome}>sources.fyi</h1>
    </div>
  </div>
</main>

<style>
  #top {
    grid-area: top;
    text-align: center;
  }

  #title {
    color: #ff1919;
    cursor: pointer;
    font-size: 1em;
    font-family: helvetica;
    font-weight: 300;
  }

  @media (min-width: 480px) {
    #top:not(.compact) #title {
      font-size: 3em;
      font-weight: 300;
      color: #ff1919;
    }
  }

  @media (max-width: 480px) {
    #title {
      /*margin-block-start: 0em;*/
      margin-block-end: 0em;
    }
  }
</style>
