// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD6D8r_ZZps4a7vqfu7K_ljmXYfKzOCOb4",
    authDomain: "originwiki.firebaseapp.com",
    projectId: "originwiki",
    storageBucket: "originwiki.appspot.com",
    messagingSenderId: "363575137435",
    appId: "1:363575137435:web:cedf00eb3f70e300bd623e",
    measurementId: "G-TYTPMEBQZG",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();
