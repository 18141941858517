<script lang="ts">
  import { appState, AppState, MODE_CREATE_ARTICLE } from "./app-state";

  function handleClick() {
    const newState: AppState = { type: MODE_CREATE_ARTICLE };
    appState.set(newState);
  }
</script>

<main>
  <button on:click|preventDefault={handleClick}>Annotate a new document</button>
</main>

<style>
  button {
    border-radius: 4pt;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
  }
</style>
