<script lang="ts" context="module">
  // Interface with server, all fields optional
  export type ArticleInfo = {
    originalUrl?: string;
    originalTitle?: string;
    creatorId?: string;
    archiveUrl?: string;
    published?: {
      seconds: number;
      nanoseconds: number;
    };
    content?: string;
  };
</script>

<script lang="ts">
  import CommentSettings from "./CommentSettings.svelte";

  export let allowComments: boolean | undefined;
  export let articleInfo: ArticleInfo;

  $: renderedTitle = articleInfo.originalTitle
    ? '"' + articleInfo.originalTitle + '"'
    : "Untitled article";
</script>

<main>
  <div id="source">
    <span id="explanation">snippet from:</span>
    <h2 id="originalArticleName">
      {#if articleInfo.archiveUrl}
        <a href={articleInfo.archiveUrl}>{renderedTitle}</a>
      {:else}
        {renderedTitle}
      {/if}
    </h2>
    {#if articleInfo.originalUrl}
      <span class="url_box">{articleInfo.originalUrl}</span>
    {:else}
      Original url not provided!
    {/if}
  </div>
  {#if allowComments !== undefined}
    <CommentSettings commentsEnabled={allowComments} />
  {/if}
</main>

<style>
  #source {
    margin-bottom: 20px;
    /*max-width:  400px;*/
    padding-top: 1em;
  }

  #explanation {
    text-transform: uppercase;
    color: gray;
    /*font-style:  italic;*/
  }

  #originalArticleName {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .url_box {
    border: dotted 1px black;
    display: block;
    /*font-size: 8pt;*/
    font-family: monospace;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .url_box::-webkit-scrollbar {
    display: none;
  }
</style>
