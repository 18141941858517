<script lang="ts" context="module">
  import StanceTag, { Stance } from "./StanceTag.svelte";

  export type Comment = {
    id: string;
    message: string;
    target: {
      range: {
        start: number;
        end: number;
      };
    };
    stance?: Stance;
    confirmed: boolean;
    originalUrl?: string;
    sourceLinkText?: string;
  };
</script>

<script lang="ts">
  import type { ArticleInfo } from "./DocumentDescription.svelte";
  import {
    addDoc,
    collection,
    DocumentReference,
    DocumentData,
  } from "firebase/firestore";

  export let comment: Comment;
  export let highlight: () => void;
  export let unhighlight: () => void;
  export let cancelComment: () => void;

  function changeStance(s: Stance) {
    if (comment.stance === s) {
      comment.stance = undefined;
      return;
    }
    comment.stance = s;
  }

  export let addArticle: ((articleInfo: ArticleInfo) => void) | undefined;

  function maybeAddArticle() {
    if (!addArticle) {
      return;
    }
    addArticle({});
  }

  export let docRef: DocumentReference<DocumentData> | undefined;

  let submitStatus = "";

  // TODO: validation, error handling
  async function onSubmit() {
    const commentInfo = {
      message: comment.message,
      originalUrl: comment.originalUrl,
      target: comment.target,
      stance: comment.stance,
      sourceLinkText: comment.sourceLinkText,
    };
    if (!docRef) {
      console.error("Cannot submit comment without docRef");
      submitStatus = "Cannot add comment on unpublished doc.";
      return;
    }

    if (
      !(
        commentInfo.stance == Stance.kDispute ||
        commentInfo.stance == Stance.kSupport ||
        commentInfo.stance == Stance.kUncertain
      )
    ) {
      console.log(commentInfo.stance, "should be in", [
        Stance.kDispute,
        Stance.kSupport,
        Stance.kUncertain,
      ]);
      submitStatus = "Please select a stance.";
      return;
    }

    if (!commentInfo.originalUrl) {
      submitStatus = "Please provide a URL.";
      return;
    }
    try {
      const url = new URL(commentInfo.originalUrl);
      console.assert(url);
    } catch (e) {
      submitStatus = "Invalid URL.";
      return;
    }

    if (
      !(
        commentInfo.originalUrl.startsWith("https://") ||
        commentInfo.originalUrl.startsWith("http://")
      )
    ) {
      submitStatus = 'URL must start with "https://" or "http://".';
      return;
    }

    if (!commentInfo.sourceLinkText) {
      submitStatus = "Please provide a title for your source.";
      return;
    }

    try {
      await addDoc(collection(docRef, "analysis"), commentInfo);
    } catch (e) {
      console.error(e);
      submitStatus = "Failed to add comment.";
      return;
    }
    comment.confirmed = true;
    comment = { ...comment };
  }
</script>

<main
  on:mouseenter={highlight}
  on:mouseleave={unhighlight}
  class:confirmed={comment.confirmed}
>
  {#if comment.confirmed}
    {#if comment.stance}
      <StanceTag stance={comment.stance} />
    {/if}
    <span>
      {comment.message}
    </span>
    {#if comment.originalUrl}
      <div id="source">
        source:
        <div id="url">
          <a href={comment.originalUrl}>
            {#if comment.sourceLinkText}
              {comment.sourceLinkText}
            {:else}
              {comment.originalUrl}
            {/if}
          </a>
        </div>
        <div id="analyze">
          <a href="/create" on:click|preventDefault={maybeAddArticle}
            >[respond]</a
          >
        </div>
      </div>
    {/if}
  {:else}
    <form on:submit|preventDefault={onSubmit}>
      Pick a stance:
      <div id="options">
        {#if comment.stance === undefined || comment.stance === Stance.kDispute}
          <StanceTag
            stance={Stance.kDispute}
            onclick={() => {
              changeStance(Stance.kDispute);
            }}
          />
        {/if}
        {#if comment.stance === undefined || comment.stance === Stance.kSupport}
          <StanceTag
            stance={Stance.kSupport}
            onclick={() => {
              changeStance(Stance.kSupport);
            }}
          />
        {/if}
        {#if comment.stance === undefined || comment.stance === Stance.kUncertain}
          <StanceTag
            stance={Stance.kUncertain}
            onclick={() => {
              changeStance(Stance.kUncertain);
            }}
          />
        {/if}
      </div>
      <input
        id="explanation"
        name="explanation"
        type="text"
        placeholder="Provide an explanation..."
        bind:value={comment.message}
      />
      <input
        id="originalUrl"
        name="originalUrl"
        type="text"
        placeholder="Provide a source url..."
        bind:value={comment.originalUrl}
      />
      <input
        id="sourceLinkText"
        name="sourceLinkText"
        type="text"
        placeholder="Provide a title for your source..."
        bind:value={comment.sourceLinkText}
      />
      <input id="submit" type="submit" value="submit" />
      {submitStatus}
    </form>
    <button id="cancel" on:click={cancelComment}>cancel</button>
  {/if}
</main>

<style>
  #options {
    /*display: grid;*/
    width: 100%;
    /*grid-template-columns: 1fr 1fr 1fr;*/
    column-gap: 0.5em;
    margin-bottom: 0.5em;
  }

  #url {
    color: gray;
    display: inline-block;
    /*border: dotted 1px black;*/
  }

  main {
    border: solid 1px #aaa;
    background-color: #fdfdfd;
    padding: 0.5em;
    border-radius: 12px;
  }

  #explanation {
    width: 100%;
  }
  #originalUrl {
    width: 100%;
  }
  #sourceLinkText {
    width: 100%;
  }

  #analyze {
    display: inline-block;
    color: gray;
    /*border: solid 1px black;*/
    /*border-radius: 1em;*/
    /*padding-left: 0.5em;*/
    /*padding-right: 0.5em;*/
  }

  main:not(.confirmed) {
    background-color: #f1f9f8;
  }

  input {
    display: inline;
  }
</style>
