<script lang="ts" context="module">
  import type { ArticleInfo } from "./DocumentDescription.svelte";
  import type { Comment } from "./Annotation.svelte";
  import type { Stance } from "./StanceTag.svelte";
  export type HighlightChunk = {
    text: string;
    // List of comments that apply to this
    comments: number[];
    documentOffset: number;
    stance?: Stance;
  };

  export type HighlightableLines = {
    kind: "highlightable";
    chunks: HighlightChunk[];
  };

  export type DocLines = {
    kind: "text";
    text: string;
    target?: number[];
    idx?: number;
  };

  export type DocMetadata = {
    kind: "articleInfo";
    articleInfo: ArticleInfo;
  };

  export type DocContent = DocLines | DocMetadata | HighlightableLines;

  export type CommentInfo = {
    comment: Comment;
    idx: number;
  };

  export type DocSegment = {
    content: DocContent;
    annotations: CommentInfo[];
    documentOffset?: number;
  };

  export type AnnotatedDocument = {
    segments: DocSegment[];
    docRef?: DocumentReference<DocumentData>;
  };

  export type ArticleReferenceDependency = {
    kind: "articleReference";
    articleId: string;
  };

  export type ArticleDependency = {
    kind: "articleInfo";
    articleInfo: ArticleInfo;
  };
  export type ExplicitDocument = {
    kind: "explicitDocument";
    doc: AnnotatedDocument;
  };
  export type DocumentDependencies =
    | ArticleReferenceDependency
    | ArticleDependency
    | ExplicitDocument;
</script>

<script lang="ts">
  import Annotation from "./Annotation.svelte";
  import DocumentDescription from "./DocumentDescription.svelte";
  import { db } from "./firebase";
  import {
    doc,
    query,
    collection,
    limit,
    getDoc,
    getDocs,
    DocumentReference,
    DocumentData,
  } from "firebase/firestore";

  export let addArticle: ((articleInfo: ArticleInfo) => void) | undefined =
    undefined;
  export let deps: DocumentDependencies;

  let debouncedDeps: DocumentDependencies;
  $: (() => {
    if (!deps) {
      return;
    }
    if (!debouncedDeps) {
      debouncedDeps = deps;
      return;
    }
    if (
      deps.kind === "articleReference" &&
      debouncedDeps.kind === "articleReference" &&
      deps.articleId == debouncedDeps.articleId
    ) {
      return;
    }
    debouncedDeps = deps;
  })();

  type SnapshotFromArticleId = {
    kind: "snapshotFromArticleId";
    articleInfo: ArticleInfo;
    comments: Comment[];
    docRef: DocumentReference<DocumentData>;
  };

  type SnapshotFromEditMode = {
    kind: "snapshotFromEditMode";
    articleInfo: ArticleInfo;
  };

  type ExplicitDocumentSnapshot = {
    kind: "explicitDocumentSnapshot";
    explicitDocument: AnnotatedDocument;
  };

  type ServerSnapshot =
    | SnapshotFromArticleId
    | SnapshotFromEditMode
    | ExplicitDocumentSnapshot;

  // We don't want updates to proposed comments to trigger additional fetches
  // of the document, therefore break it out into an intermediate step.
  let serverSnapshot: Promise<ServerSnapshot>;
  $: serverSnapshot = getServerSnapshot(debouncedDeps);

  let proposedComments: Comment[] = [];

  let docPromise: Promise<AnnotatedDocument>;
  $: docPromise = getAnnotatedDocument(serverSnapshot, proposedComments);

  $: (async (docPromise: Promise<AnnotatedDocument>) => {
    try {
      await docPromise;
    } catch (e) {
      console.log(e);
    }
  })(docPromise);

  export let allowComments: boolean | undefined;
  export let isNested = false;

  let selectedIndex = -1;
  let selectedComment = -1;

  let highlightRegion = (i: number, segmentCommentIndex: number) => {
    selectedIndex = i;
    selectedComment = segmentCommentIndex;
  };

  async function fetchComments(
    docRef: DocumentReference<DocumentData>
  ): Promise<Comment[]> {
    let comments: Comment[] = [];
    // TODO: This is a lot of document reads, it can be optimized
    const q = query(collection(docRef, "analysis"), limit(10));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      comments.push({
        id: doc.id,
        message: data.message,
        target: data.target,
        stance: data.stance,
        originalUrl: data.originalUrl,
        confirmed: true,
        sourceLinkText: data.sourceLinkText,
      });
    });
    return comments;
  }

  async function getServerSnapshot(deps: DocumentDependencies) {
    switch (deps.kind) {
      case "articleReference":
        const docRef = doc(db, "articles", deps.articleId);
        if (docRef === undefined) {
          throw Error("Failed to create docRef.");
        }
        const retrieved = await getDoc(docRef);
        if (!retrieved.exists() || !retrieved.data().content) {
          throw Error("doc does not exist");
        }
        const snapshot1: SnapshotFromArticleId = {
          kind: "snapshotFromArticleId",
          articleInfo: retrieved.data(),
          comments: await fetchComments(docRef),
          docRef,
        };
        return snapshot1;
      case "articleInfo":
        const snapshot2: SnapshotFromEditMode = {
          kind: "snapshotFromEditMode",
          articleInfo: deps.articleInfo,
        };
        return snapshot2;
      case "explicitDocument":
        const snapshot3: ExplicitDocumentSnapshot = {
          kind: "explicitDocumentSnapshot",
          explicitDocument: deps.doc,
        };
        return snapshot3;
    }
  }

  async function getAnnotatedDocument(
    serverSnapshot: Promise<ServerSnapshot>,
    proposedComments: Comment[]
  ): Promise<AnnotatedDocument> {
    const snapshot = await serverSnapshot;
    switch (snapshot.kind) {
      case "explicitDocumentSnapshot":
        return snapshot.explicitDocument;
      case "snapshotFromArticleId":
        return generateDocumentStructure(
          snapshot.articleInfo,
          snapshot.comments.concat(proposedComments),
          snapshot.docRef
        );
      case "snapshotFromEditMode":
        return generateDocumentStructure(
          snapshot.articleInfo,
          proposedComments,
          undefined
        );
    }
  }

  function generateDocumentStructure(
    articleInfo: ArticleInfo,
    comments: Comment[],
    docRef?: DocumentReference<DocumentData>
  ): AnnotatedDocument {
    const fetchedBody: string = articleInfo.content || "(no content)";
    const generatedDocument: AnnotatedDocument = {
      segments: [
        {
          content: {
            kind: "articleInfo",
            articleInfo,
            // text: "The quick brown fox"
          },
          annotations: [],
        },
      ],
      docRef,
    };

    let events = [];
    for (let i = 0; i < comments.length; i++) {
      const comment = comments[i];
      events.push({
        kind: "start",
        idx: i,
        position: comment.target.range.start,
      });
      events.push({ kind: "end", idx: i, position: comment.target.range.end });
    }

    events.sort((first, second) => {
      return first.position - second.position;
    });

    //    <--------doc------------------------------------->
    //    <-----------segment-----><-----------segment----->
    //    <-chunk-><----chunk-----><----chunk---><---chunk->

    let chunkAnnotations = new Set<number>();
    let segmentAnnotations = [];
    let currentSegmentStart = 0;
    let currentChunkStart = 0;

    let highlightableChunks: HighlightChunk[] = [];

    for (const event of events) {
      let stance: Stance | undefined;
      for (const commentIdx of chunkAnnotations) {
        if (comments[commentIdx].stance === undefined) {
          continue;
        }
        stance = comments[commentIdx].stance;
        break;
      }

      const highlightChunk: HighlightChunk = {
        text: fetchedBody.substring(currentChunkStart, event.position),
        comments: Array.from(chunkAnnotations),
        documentOffset: currentChunkStart,
        stance,
      };
      highlightableChunks.push(highlightChunk);
      currentChunkStart = event.position;

      if (event.kind == "start") {
        segmentAnnotations.push(event.idx);
        chunkAnnotations.add(event.idx);
      } else {
        console.assert(event.kind == "end");

        if (chunkAnnotations.size == 1) {
          // End of cluster of comments -- add annotated section.

          const annotations: CommentInfo[] = [];
          for (const commentIdx of segmentAnnotations) {
            const comment = comments[commentIdx];
            annotations.push({
              comment,
              idx: commentIdx,
            });
          }
          const annotatedSegment: DocSegment = {
            content: {
              kind: "highlightable",
              chunks: highlightableChunks,
            },
            annotations,
            documentOffset: currentSegmentStart,
          };
          generatedDocument.segments.push(annotatedSegment);
          segmentAnnotations = [];
          currentSegmentStart = event.position;
          highlightableChunks = [];
        }
        chunkAnnotations.delete(event.idx);
      }
    }

    if (currentSegmentStart < fetchedBody.length) {
      // Add unannotated segment
      const unannotatedSegement: DocSegment = {
        content: {
          kind: "text",
          text: fetchedBody.substring(currentSegmentStart, fetchedBody.length),
        },
        annotations: [],
        documentOffset: currentSegmentStart,
      };
      generatedDocument.segments.push(unannotatedSegement);
      currentSegmentStart = fetchedBody.length;
    }

    return generatedDocument;
  }

  function checkForCommentProposal() {
    const selection = window.getSelection();
    if (!selection || selection.isCollapsed) {
      return;
    }
    if (!selection.anchorNode || !selection.focusNode) {
      return;
    }
    const anchorParentElement = selection.anchorNode.parentElement;
    const focusParentElement = selection.focusNode.parentElement;
    if (!anchorParentElement || !focusParentElement) {
      return;
    }
    // TODO: Check that start and end are in same document.
    if (!anchorParentElement.dataset.sourceoffset) {
      return;
    }
    if (!focusParentElement.dataset.sourceoffset) {
      return;
    }
    const anchorDocPosition =
      selection.anchorOffset + Number(anchorParentElement.dataset.sourceoffset);
    const focusDocPosition =
      selection.focusOffset + Number(focusParentElement.dataset.sourceoffset);

    const start = Math.min(anchorDocPosition, focusDocPosition);
    const end = Math.max(anchorDocPosition, focusDocPosition);

    proposedComments = proposedComments
      .filter((c) => c.confirmed)
      .concat([
        {
          id: "whatever",
          message: "",
          target: { range: { start, end } },
          confirmed: false,
        },
      ]);
  }
</script>

<main>
  <div
    id="container"
    class="container"
    class:nested={isNested}
    on:mouseup={checkForCommentProposal}
    on:touchend={checkForCommentProposal}
  >
    {#await docPromise}
      Loading doc...
    {:then doc}
      {#each doc.segments as segment, i}
        <div class="segment" data-sourceoffset={segment.documentOffset}>
          {#if segment.content.kind == "highlightable"}
            {#each segment.content.chunks as chunk}
              <span
                class:highlightable={chunk.comments.length !== 0}
                class:selected={selectedIndex === i &&
                  chunk.comments.includes(selectedComment)}
                class:disputed={chunk.stance === "dispute"}
                class:supported={chunk.stance === "support"}
                class:uncertain={chunk.stance === "uncertain"}
                data-sourceoffset={chunk.documentOffset}>{chunk.text}</span
              >
            {/each}
          {:else if segment.content.kind == "text"}
            {segment.content.text}
          {:else if segment.content.kind == "articleInfo"}
            <DocumentDescription
              articleInfo={segment.content.articleInfo}
              {allowComments}
            />
          {/if}
        </div>

        <div>
          {#if segment.annotations.length !== 0}
            <div class="annotations">
              {#each segment.annotations as annotation}
                <Annotation
                  comment={annotation.comment}
                  highlight={() => {
                    highlightRegion(i, annotation.idx);
                  }}
                  unhighlight={() => {
                    highlightRegion(-1, -1);
                  }}
                  cancelComment={() => {
                    proposedComments = [];
                  }}
                  docRef={doc.docRef}
                  {addArticle}
                />
              {/each}
            </div>
          {/if}
        </div>
      {/each}
    {:catch error}
      Failed to load document due to error: {error}.
    {/await}
  </div>
</main>

<style>
  #container {
    column-gap: 1em;
  }

  #container.nested {
    border: solid 1px gray;

    /*compensate for border width*/
    margin-bottom: -1px;
  }

  /* In compact mode a annotations behaves like a special segment */
  .annotations {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  #container.nested .segment {
    background-color: initial;
  }

  .segment {
    white-space: pre-line;
  }

  .highlightable {
    background-color: #d0f3eb;
    white-space: pre-line;
  }

  .highlightable.supported {
    background-color: #e0efd0;
  }

  .highlightable.disputed {
    background-color: #ffe4e4;
  }

  .highlightable.uncertain {
    background-color: #e7e8ef;
  }

  .selected {
    background-color: yellow !important;
  }
</style>
