<script lang="ts" context="module">
  import type { Timestamp } from "firebase/firestore";
  export type SearchResultData = {
    id: string;
    title: string;
    published: Timestamp;
  };
</script>

<script lang="ts">
  import {
    appState,
    ArticleViewState,
    generatePathFromId,
    MODE_SELECTED_ARTICLE,
  } from "./app-state";
  import { onMount } from "svelte";

  export let searchResultData: SearchResultData;

  function handleClick() {
    const newAppState: ArticleViewState = {
      type: MODE_SELECTED_ARTICLE,
      articleId: searchResultData.id,
    };
    appState.set(newAppState);
  }

  let linkTarget: string;
  onMount(() => {
    linkTarget = generatePathFromId(searchResultData.id);
  });
</script>

<main>
  <div class="result">
    <a href={linkTarget} on:click|preventDefault={handleClick}>
      {searchResultData.title}</a
    >
    <div id="resultPublishTime">
      -- published {searchResultData.published.toDate().toLocaleDateString()}
    </div>
  </div>
</main>

<style>
  .result {
    /*border: solid 1px gray;*/
    padding: 1em;
    /*border-radius: 8px;*/
  }

  .result:hover {
    cursor: pointer;
  }

  #resultPublishTime {
    display: inline-block;
    color: gray;
  }
</style>
