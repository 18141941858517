<script lang="ts">
  import { db } from "./firebase";
  import {
    collection,
    query,
    getDocs,
    orderBy,
    limit,
  } from "firebase/firestore";

  import Header from "./Header.svelte";

  import CreateArticleButton from "./CreateArticleButton.svelte";
  import SearchResult, { SearchResultData } from "./SearchResult.svelte";
  import SearchBar from "./SearchBar.svelte";

  let recentArticles: SearchResultData[] = [];

  const articlesRef = collection(db, "articles");

  (async () => {
    const q = query(articlesRef, orderBy("published", "desc"), limit(6));

    const querySnapshot = await getDocs(q);
    const partialArticles: SearchResultData[] = [];
    querySnapshot.forEach((doc) => {
      partialArticles.push({
        id: doc.id,
        title: doc.data().originalTitle,
        published: doc.data().published,
      });
    });
    recentArticles = partialArticles;
  })();
</script>

<main>
  <div id="container">
    <div id="header">
      <Header />
    </div>
    <div id="search">
      <SearchBar />
    </div>
    <div id="create">
      <CreateArticleButton />
    </div>
    <div id="articles">
      <div id="recentArticlesTitle">Recent snapshots</div>
      {#each recentArticles as article (article.id)}
        <SearchResult searchResultData={article} />
      {/each}
    </div>
  </div>
</main>

<style>
  #header {
    grid-area: header;
  }

  #search {
    grid-area: search;
  }

  #articles {
    grid-area: articles;
    border: solid 1px black;
    padding: 1em;
  }

  #recentArticlesTitle {
    text-transform: uppercase;
  }

  #create {
    grid-area: create;
  }

  #container {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 960px) 1fr;
    grid-template-areas:
      "header header header"
      ". create ."
      ". search ."
      " . articles .";
    width: 100%;
    grid-gap: 1em;
  }
</style>
