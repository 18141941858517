<script lang="ts">
  import { appState, MODE_SELECTED_ARTICLE } from "./app-state";
  import Header from "./Header.svelte";
  import CreateArticleFlow from "./CreateArticleFlow.svelte";

  function onCreated(articleId: string) {
    appState.set({ type: MODE_SELECTED_ARTICLE, articleId });
  }
</script>

<main>
  <div id="container">
    <div id="header">
      <Header compactMode={true} />
    </div>
    <div id="create">
      <CreateArticleFlow {onCreated} />
    </div>
  </div>
</main>

<style>
  #header {
    grid-area: header;
  }
  #create {
    grid-area: create;
  }

  #container {
    display: grid;
    grid-template-columns: 1fr 960px 1fr;
    grid-template-areas:
      "header header header"
      ". create .";
    max-width: 100%;
    grid-gap: 1em;
    padding-top: 1em;
  }

  @media (max-width: 960px) {
    #container {
      grid-template-columns: 1fr minmax(auto, 472px) 1fr;
      grid-template-areas:
        "header header header"
        ". create ."
        ". create .";
      grid-column-gap: 0px;
      grid-row-gap: 1em;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
</style>
