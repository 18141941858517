<script lang="ts">
  import { db } from "./firebase";
  import {
    addDoc,
    collection,
    DocumentReference,
    DocumentData,
    Timestamp,
  } from "firebase/firestore";
  import type { ArticleInfo } from "./DocumentDescription.svelte";
  import AnnotatedDocument from "./AnnotatedDocument.svelte";

  let errorMessage: string = "";

  // TODO: combine with similar code in Annotation.svelte
  function validateUrl(userUrl: string) {
    try {
      const url = new URL(userUrl);
      console.assert(url);
    } catch (e) {
      errorMessage = "Invalid URL.";
      return false;
    }

    if (!(userUrl.startsWith("https://") || userUrl.startsWith("http://"))) {
      errorMessage = 'URL must start with "https://" or "http://".';
      return false;
    }
    return true;
  }

  async function onSubmit(e: SubmitEvent) {
    if (!(e.target instanceof HTMLFormElement)) {
      console.error("SubmitEvent in CreateArticlePage had bad target.");
      return;
    }

    if (!articleInfo.originalTitle) {
      errorMessage = "Please provide a title.";
      return;
    }
    if (!articleInfo.originalUrl) {
      errorMessage = "Please provide the original URL.";
      return;
    }

    if (!validateUrl(articleInfo.originalUrl)) {
      return;
    }

    if (articleInfo.archiveUrl && !validateUrl(articleInfo.archiveUrl)) {
      return;
    }

    if (!articleInfo.content) {
      return;
    }

    articleInfo.published = Timestamp.now();
    let docRef: DocumentReference<DocumentData>;
    try {
      docRef = await addDoc(collection(db, "articles"), articleInfo);
    } catch (e) {
      errorMessage = "Could not submit article.";
      throw e;
      return;
    }
    errorMessage = "";
    onCreated(docRef.id);
  }

  let articleInfo: ArticleInfo = {};

  export let onCreated: (articleId: string) => void;
</script>

<main>
  <div id="editor">
    <form id="form" on:submit|preventDefault={onSubmit}>
      Original title:
      <input
        id="originalTitle"
        name="original_title"
        type="text"
        placeholder="Florida man catches big fish"
        bind:value={articleInfo.originalTitle}
      />
      <br />
      Original url:
      <input
        id="originalUrl"
        name="original_url"
        type="text"
        placeholder="https://www.example.com"
        bind:value={articleInfo.originalUrl}
      />
      <br />
      Archive url:
      <input
        id="archiveUrl"
        name="archive_url"
        type="text"
        placeholder="https://www.archive.is/"
        bind:value={articleInfo.archiveUrl}
      />
      <br />
      Original text:
      <br />
      <textarea
        cols="50"
        rows="20"
        id="originalText"
        name="original_text"
        type="text"
        placeholder="Lorem ipsum"
        bind:value={articleInfo.content}
      />
      <br />
      <input id="submit" type="submit" value="submit" />
      <div id="error">{errorMessage}</div>
    </form>
  </div>
  <div id="preview">
    PREVIEW
    <div id="docPreview">
      <AnnotatedDocument
        deps={{ kind: "articleInfo", articleInfo }}
        allowComments={false}
        isNested={false}
      />
    </div>
  </div>
</main>

<style>
  #docPreview {
    border: solid 1px gray;
    padding-left: 1em;
    padding-bottom: 1em;
  }

  main {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(auto, 472px));
    grid-gap: 1em;
  }

  textarea {
    width: 100%;
  }

  #error {
    color: red;
  }
</style>
