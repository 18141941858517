import { writable } from "svelte/store";
export const MODE_SEARCH_RESULTS = "search_results";
export const MODE_SELECTED_ARTICLE = "selected_article";
export const MODE_WELCOME_PAGE = "welcome_page";
export const MODE_CREATE_ARTICLE = "create_article";
export const appState = writable(undefined);
export function generatePathFromId(id) {
    return "/a/" + id;
}
appState.subscribe((value) => {
    if (value === undefined) {
        return;
    }
    let title = "sources.fyi";
    let newUrl = "/";
    switch (value.type) {
        case MODE_SEARCH_RESULTS:
            title += ": search results for " + value.query;
            newUrl = "/search?q=" + encodeURIComponent(value.query);
            break;
        case MODE_SELECTED_ARTICLE:
            title += ": viewing article [" + value.articleId + "]";
            newUrl = generatePathFromId(value.articleId);
            break;
        case MODE_WELCOME_PAGE:
            break;
        case MODE_CREATE_ARTICLE:
            title += ": creating article";
            newUrl = "/create";
            break;
    }
    window.history.pushState(value, title, newUrl);
});
function getArticleStateFromUrl(location) {
    if (!location.pathname) {
        console.error("location.path not set");
        return null;
    }
    if (!location.pathname.startsWith("/a/")) {
        return null;
    }
    const re = /^\/a\/([a-zA-Z0-9]*)$/;
    const matchResult = location.pathname.match(re);
    if (!matchResult) {
        console.error("location.path: ", location.pathname, "did not match", re);
        return null;
    }
    return { type: MODE_SELECTED_ARTICLE, articleId: matchResult[1] };
}
function getSearchStateFromUrl(location) {
    if (location.pathname !== "/search") {
        return null;
    }
    const urlSearchParams = new URLSearchParams(decodeURIComponent(location.search));
    const params = Object.fromEntries(urlSearchParams.entries());
    if (!params.q) {
        return null;
    }
    return { type: MODE_SEARCH_RESULTS, query: params.q };
}
function getCreateArticleStateFromUrl(location) {
    if (location.pathname !== "/create") {
        return null;
    }
    return { type: MODE_CREATE_ARTICLE };
}
function getAppStateFromUrl(currentLocation) {
    const articleState = getArticleStateFromUrl(currentLocation);
    if (articleState) {
        return articleState;
    }
    const searchState = getSearchStateFromUrl(currentLocation);
    if (searchState) {
        return searchState;
    }
    const createArticleState = getCreateArticleStateFromUrl(currentLocation);
    if (createArticleState) {
        return createArticleState;
    }
    return { type: MODE_WELCOME_PAGE };
}
window.onpopstate = function (event) {
    appState.set(event.state);
    event.preventDefault();
};
const initialState = getAppStateFromUrl(window.location);
appState.set(initialState);
