<script lang="ts">
  export let commentsEnabled: boolean;
</script>

<main>
  {#if commentsEnabled}
    <div id="comment_settings" class="enabled">open discussion</div>
  {:else}
    <div id="comment_settings" class="disabled">comments closed</div>
  {/if}
</main>

<style>
  #comment_settings {
    background: gray;
    border-radius: 10px;
    color: white;
    display: inline-block;
    padding: 6px;
    font-size: 8pt;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  #comment_settings.enabled {
    background: #c0e995;
    color: #136213;
  }
  #comment_settings.disabled {
    background: #e77575;
    color: white;
  }
</style>
