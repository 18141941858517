<script lang="ts">
  import SearchResult, { SearchResultData } from "./SearchResult.svelte";
  import Header from "./Header.svelte";
  import { collection, query, where, getDocs, limit } from "firebase/firestore";
  import { db } from "./firebase";
  import SearchBar from "./SearchBar.svelte";
  import CreateArticleButton from "./CreateArticleButton.svelte";

  export let queryString: string;
  let loading = false;

  let results: SearchResultData[] = [];

  const articlesRef = collection(db, "articles");

  const fetchResults = async (queryString: string) => {
    loading = true;
    results = [];
    const q = query(
      articlesRef,
      where("originalUrl", "==", queryString),
      limit(3)
    );

    const querySnapshot = await getDocs(q);
    let partialResults: SearchResultData[] = [];
    querySnapshot.forEach((doc) => {
      partialResults.push({
        id: doc.id,
        title: doc.data().originalTitle,
        published: doc.data().published,
      });
    });
    results = partialResults;
    loading = false;
  };

  $: {
    fetchResults(queryString);
  }
</script>

<main>
  <div id="container">
    <div id="header">
      <Header />
    </div>
    <div id="search">
      <SearchBar query={queryString} />
    </div>
    <div id="results">
      {#if results.length === 0}
        {#if loading}
          <p>Loading results for query: [{queryString}]...</p>
        {:else}
          <p>
            No results were found. Consider starting a new annotated document:
          </p>
          <CreateArticleButton />
        {/if}
      {:else}
        <p>Search results for:[{queryString}]:</p>
        {#each results as result (result.id)}
          <SearchResult searchResultData={result} />
        {/each}
      {/if}
    </div>
  </div>
</main>

<style>
  #header {
    grid-area: header;
  }

  #search {
    grid-area: search;
  }

  #results {
    grid-area: results;
  }

  #container {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 960px) 1fr;
    grid-template-areas:
      "header header header"
      ". search ."
      " . results .";
    width: 100%;
    grid-gap: 1em;
    margin-top: 1em;
  }
</style>
