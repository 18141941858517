<script lang="ts">
  import {
    appState,
    AppState,
    MODE_SEARCH_RESULTS,
    MODE_SELECTED_ARTICLE,
    MODE_WELCOME_PAGE,
    MODE_CREATE_ARTICLE,
  } from "./app-state";
  import WelcomePage from "./WelcomePage.svelte";
  import SearchResults from "./SearchResults.svelte";
  import ArticlePage from "./ArticlePage.svelte";
  import CreateArticlePage from "./CreateArticlePage.svelte";

  let renderedAppState: AppState | undefined;

  appState.subscribe((value) => {
    if (value === undefined) {
      return;
    }
    renderedAppState = value;
  });
</script>

<main>
  {#if renderedAppState === undefined}
    <!-- we shouldn't be in this state for too long -->
  {:else if renderedAppState.type == MODE_WELCOME_PAGE}
    <WelcomePage />
  {:else if renderedAppState.type == MODE_SEARCH_RESULTS}
    <SearchResults queryString={renderedAppState.query} />
  {:else if renderedAppState.type == MODE_SELECTED_ARTICLE}
    <ArticlePage articleId={renderedAppState.articleId} />
  {:else if renderedAppState.type == MODE_CREATE_ARTICLE}
    <CreateArticlePage />
  {/if}
</main>

<style>
</style>
