<script lang="ts">
  import Header from "./Header.svelte";
  import AnnotatedDocument from "./AnnotatedDocument.svelte";
  import CreateArticleFlow from "./CreateArticleFlow.svelte";
  import type { ArticleInfo } from "./DocumentDescription.svelte";

  export let articleId: string;

  let properties = {
    commentsEnabled: true,
  };

  let contentSequence: string[];
  $: contentSequence = [articleId];

  let createArticleInfo: ArticleInfo | undefined = undefined;

  function addArticle(index: number, articleInfo: ArticleInfo) {
    contentSequence = contentSequence.slice(0, index + 1);
    createArticleInfo = articleInfo;
  }

  function onArticleCreated(articleId: string) {
    contentSequence = [...contentSequence, articleId];
    createArticleInfo = undefined;
  }
</script>

<main>
  <div id="container">
    <div id="header">
      <Header compactMode={true} />
    </div>
    <div id="articles">
      {#each contentSequence as articleId, i (articleId)}
        <div class="article">
          <AnnotatedDocument
            deps={{ kind: "articleReference", articleId }}
            allowComments={properties.commentsEnabled}
            isNested={false}
            addArticle={(articleInfo) => {
              addArticle(i, articleInfo);
            }}
          />
        </div>
      {/each}
      {#if createArticleInfo}
        <div class="create">
          <CreateArticleFlow onCreated={onArticleCreated} />
        </div>
      {/if}
    </div>
  </div>
</main>

<style>
  #header {
    grid-area: header;
  }

  .article {
    max-width: 500px;
  }

  #articles {
    grid-area: articles;
  }

  .create {
    max-width: 500px;
  }

  #container {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "header ."
      "articles articles";
    padding: 1em;
    padding-bottom: 6em;
  }

  #articles {
    display: flex;
    justify-content: center;
    gap: 2em;
  }

  @media (max-width: 600px) {
    .article {
      max-width: 100%;
    }
  }
</style>
