<script lang="ts" context="module">
  export enum Stance {
    kDispute = "dispute",
    kSupport = "support",
    kUncertain = "uncertain",
  }
</script>

<script lang="ts">
  export let stance: Stance;
  export let onclick: (() => void) | undefined = undefined;

  function maybeClick() {
    if (onclick) {
      onclick();
    }
  }

  const clickable = onclick !== undefined;
</script>

<main
  id="status"
  class:disputed={stance == Stance.kDispute}
  class:supported={stance == Stance.kSupport}
  class:uncertain={stance == Stance.kUncertain}
  on:click={maybeClick}
  class:clickable
>
  {#if stance === Stance.kDispute}
    nope
  {:else if stance === Stance.kSupport}
    yup
  {:else if stance === Stance.kUncertain}
    idk
  {/if}
</main>

<style>
  #status {
    padding: 0.4em;
    border-radius: 1em;
    display: inline-block;
    margin-bottom: 0.5em;
    font-weight: 500;
    font-size: 8pt;
    text-transform: uppercase;

    background-color: white;
    color: black;
    border: solid 1px black;
  }

  #status.clickable {
    cursor: pointer;
  }

  #status.disputed {
    background-color: #e77575;
    color: white;
  }

  #status.supported {
    background-color: #c1e995;
    color: #136213;
  }

  #status.uncertain {
    background-color: #e0e1e7;
  }
</style>
