<script lang="ts">
  import {
    appState,
    SearchResultState,
    MODE_SEARCH_RESULTS,
  } from "./app-state";

  function getQuery(f: FormData) {
    for (let field of f) {
      if (field[0] === "query") {
        return field[1];
      }
    }
    return undefined;
  }

  function onSubmit(e: SubmitEvent) {
    if (!(e.target instanceof HTMLFormElement)) {
      console.error("SubmitEvent had bad target.");
      return;
    }
    const formData = new FormData(e.target);

    const possibleQuery = getQuery(formData);

    if (possibleQuery === undefined) {
      console.error("Could not find query");
      return;
    }

    if (typeof possibleQuery !== "string") {
      console.error("Query is not string");
      return;
    }

    const newAppState: SearchResultState = {
      type: MODE_SEARCH_RESULTS,
      query: possibleQuery,
    };
    appState.set(newAppState);
  }

  export let query = "";
</script>

<main>
  <form id="container" on:submit|preventDefault={onSubmit}>
    <input
      id="input"
      name="query"
      type="text"
      placeholder="Look up an article by URL"
      bind:value={query}
    />
    <input id="submit" type="submit" value="🔍" alt="Search" />
  </form>
</main>

<style>
  #input {
    grid-area: input;
    border-radius: 8px;
    background-color: initial;
    border: none;
  }
  #submit {
    grid-area: submit;
    border: none;
    background-color: initial;
    cursor: pointer;
    padding-right: 0.8em;
  }

  #container {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1fr auto;
    grid-template-areas: "input submit";
    border: solid 1px #dfdfdf;
    border-radius: 12px;
  }
</style>
